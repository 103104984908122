<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <div class="form_item">
            <div class="form_label">账号</div>
            <Input :disabled="isEdit" class="width_300" type="text" v-model.trim="formData.username" :maxlength="15" show-word-limit></Input>
        </div>
        <div class="form_item">
            <div class="form_label must_input">密码</div>
            <Input class="width_300" type="password" password v-model.trim="formData.passWord" :maxlength="20"></Input>
        </div>
        <div class="form_item">
            <div class="form_label must_input">确认密码</div>
            <Input class="width_300" type="password" password v-model.trim="formData.confirmpassWord" :maxlength="20"></Input>
        </div>
        <div class="form_item">
            <div class="form_label must_input">账号昵称</div>
            <Input class="width_300" v-model.trim="formData.nickname" :maxlength="10" show-word-limit></Input>
        </div>

        <div class="form_item">
            <div class="form_label must_input">角色组</div>
            <Select class="width_300" v-model="formData.groupid">
                <Option v-for="roleInfo in roleList" :key="roleInfo.id" :value="roleInfo.id" :label="roleInfo.groupname"></Option>
            </Select>
        </div>
        <div class="form_item">
            <div class="form_label">状态</div>
            <RadioGroup v-model="formData.status" type="button" button-style="solid">
                <Radio label="1">开启</Radio>
                <Radio label="0">关闭</Radio>
            </RadioGroup>
        </div>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">保存</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import editor from '@/components/editor';
import MyDatetimerange from '@/components/myDatetimerange.vue';
import { reqCreateAccount, reqSaveAccount, reqGroupList } from '@/lib/request/auth';

export default {
    name: 'accountAdd',
    components: {
        editor,
        MyDatetimerange,
    },
    data() {
        return {
            title: null,
            roleList: [],
            formData: {
                id: null,
                username: null,
                passWord: null,
                confirmpassWord: '',
                nickname: null,
                groupid: null,
                status: '1',
            },
            isEdit: false,
        };
    },
    mounted() {
        this.routeParamsInjectFormData();
        this.title = this.isEdit ? '编辑账号' : '添加账号';
        this.getRoleList();
    },
    methods: {
        //获取角色组列表
        getRoleList() {
            this.showAjaxLoading();
            reqGroupList()
                .then(res => {
                    this.roleList = res.data.list || [];
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //取消
        onCloseEdit() {
            this.$router.back();
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { username, passWord, confirmpassWord, nickname, groupid } = params || {};
            let vs = [
                { value: username, tip: '请输入手机号码' },
                { value: passWord, tip: '请输入登录密码' },
                { value: confirmpassWord, tip: '请输入确认密码' },
                { value: nickname, tip: '请输入账号昵称' },
                { value: groupid, tip: '请选择角色组' },
            ];
            let fn = this.isEdit ? reqSaveAccount : reqCreateAccount;
            params.userPhone = params.username;
            params.nickName = params.nickname;
            params.groupId = params.groupid;
            delete params.username;
            delete params.confirmpassWord;
            delete params.nickname;
            delete params.groupid;

            hm.validateForm(vs)
                .then(() => {
                    if (passWord != confirmpassWord) {
                        this.fadeWarning('登录密码与确认密码不一致');
                        this.hideAjaxLoading();
                        return;
                    }
                    fn(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.goUrl('/sysManagerAccountList');
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
